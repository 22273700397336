
        import React, { useEffect, useState, useRef, useContext } from 'react'

        import CustomDropdown from './CustomDropdown2';
        import useRead from '../../src/domain/services/hookRead';
        import { ReadSearch } from '../domain/services/readSearch';        

        const SelectSingleDbCentroCusto = ({ field, formik,loading, options,optionSelected, listener,required,type, optionDefault,onBlurHandler, labelField, placeholder, label,ref,additionalName }) => {      
                const [listcentro_custo, isLoadingcentro_custo, isErrorcentro_custo] = useRead({
                    table: "tb_centro_custo",
                    src: 'centro_custo',
                    where: [["centro_custo_id", "!=", "0"]],
                    orderBy: "centro_custo_name asc",
                    limit: "50"
                },ReadSearch);                
                
            return (
                
                <CustomDropdown
                    field={field}
                    label={label??"Centro de custo"}
                    options={[listcentro_custo, 'tb_centro_custo', 'centro_custo_name', 'centro_custo_id']} 
                    labelField="name" 
                    type={type}
                    additionalName={additionalName}
                    optionDefault={formik.value}
                    loading={isLoadingcentro_custo} 
                    //listener={onListener}onCha
                    optionSelected='centro_custo_name' 
                    required={true} 
                    //onBlurHandler={()=> loadConfigCertificate()}
                    placeholder="Centro de custo"
                    formik={formik}
                />

            );
        }

        export default SelectSingleDbCentroCusto; 