export const ArrayToQParams = (data) => {
  let queryString = '';

  // Verifica se há cláusula WHERE
  if (data && data.where && data.where.length > 0) {
    // Monta a cláusula WHERE
    data.where.forEach(([column, operator, value]) => {
      // Verifica se o valor é um array e o formata corretamente
      if (Array.isArray(value)) {
        value = value.join(',');
      }
      // Adiciona a condição à string de consulta
      queryString += `~${column}|${operator}|${value}`;
    });
  } else {
    queryString += "~";

  }

  if (data.groupBy) {
    queryString += `&group_by=${encodeURIComponent(data.groupBy)}`;
  }

  if (data.orderBy) {
    queryString += `&sort=${encodeURIComponent(data.orderBy)}`;
  }

  if (data.page) 
    queryString += `&page=${data.page}`;
  
  if (data.limit) 
    queryString += `&limit=${data.limit}`;
  
  if (data.rowsCountTotal) {
    queryString += `&rows_count_total=true`;
  }

  if (data.leftJoin || data.left_join) {
    queryString += `&left_join=${data.leftJoin}`;
  }
//console.log("::: queryString ::: ",queryString);
  // Remove o primeiro "&" da string de consulta
  queryString = queryString.substr(1);  
  return "?w=" + queryString;
}
/* 
const data = {
  where: [
    ["status_id", "IN", [1, 2]],
    ["user_name", "LIKE", '%fulano'],
    ["_created_at", "BETWEEN", ['2024-01-24', '2024-01-21']],
    ["_created_user_id", "IN", [1, 2, 3]],
    ["_responsible_user_id", "IN", [1, 2, 3]],
    ["filial_id", "!=", 0]
  ],
  groupBy: "A.column desc",
  limit: 10
};

const queryString = convertToQueryString(data);
//console.log('?' + queryString);
 */